import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import AboutPanel from '../components/AboutPanel'

const About = () => {
    return (
        <div>
            <Navbar />
            <Sidebar />
            <AboutPanel/>
        </div>
    )
}

export default About
