import React from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../assets/avatar.png'
import './VideoStyles.css'

//#ffe945, #f9ab4d
const HeroPanel = () => {
    return (
        <div className='hero-panel'>
            <span class="dot-one"></span>
            <span class="dot-two"></span>
            <div className='hero-card-placeholder'></div>
            <Card className='hero-card'>
                <Container className='container'>
                    <Row>
                        <Col className='text'><div className='title'>
                            Hi. I'm Marek,</div>
                            <br/>
                            <div className='description'>
                                Software Developer & aspiring Data Scientist who also loves UI/UX design.</div></Col>
                        <a href="https://rarible.com/token/polygon/0x93a3bed72cbd910ca7e7f8e4433cc603c80c5ed9:0"><Col className='avatar'><img className='image' src={logo} alt='Avatar'/></Col></a>
                    </Row>
                </Container>
            </Card>
        </div>)
}

export default HeroPanel
