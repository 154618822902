import React from 'react'
import Navbar from '../components/Navbar'

const Blog = () => {
    return (
        <div>
            <Navbar />
            <h1>Blog</h1>
        </div>
    )
}

export default Blog
