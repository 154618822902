import React from 'react'
import Navbar from '../components/Navbar'

const Work = () => {
    return (
        <div>
            <Navbar />
            <h1>Work</h1>
        </div>
    )
}

export default Work
