import React from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../assets/avatar.png'
import './VideoStyles.css'

//#ffe945, #f9ab4d
const AboutPanel = () => {
    return (
        <div className='hero-panel'>
            <span class="dot-one-about"></span>
            <span class="dot-two-about"></span>
            <div className='hero-card-placeholder'></div>
            <Card className='hero-card'>
                <Container className='container'>
                    <Row>
                        <Col className='text'><div className='title'>
                            About Me</div>
                            <br/>
                            <div className='description'>
                                 </div></Col>
                        <Col className='avatar'><img className='image' src={logo} alt='Avatar'/></Col>
                    </Row>
                </Container>
            </Card>
        </div>)
}

export default AboutPanel
