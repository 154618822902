import React from 'react'
import Navbar from '../components/Navbar'
import './HomeStyles.css'
import HeroPanel from '../components/HeroPanel'
import Sidebar from '../components/Sidebar'

const Home = () => {
    return (
        <div className='home'>
            <Sidebar></Sidebar>
            <Navbar />
            <HeroPanel />
        </div>
    )
}

export default Home
