import React from 'react'
import Navbar from '../components/Navbar'

const Contact = () => {
    const htmlString = '<table id="fullSignature" width="300" cellspacing="0" cellpadding="0" border=2" style="margin-left:30px;margin-top:50px;"> <tbody> <tr> <td style="vertical-align:top;text-align:left"> <span style="font-size:16px;font-family:&quot;Century Gothic&quot;,Helvetica,sans-serif;color:#003660">Marek Lorenz</span><!--anchor--> </td> </tr> <tr> <td style="vertical-align:top;text-align:left"> <!--anchor--> </td> </tr> <tr> <td style="vertical-align:top;text-align:left;padding-top:4px;padding-bottom:4px"> <!--anchor--> </td> </tr> <tr> <td style="vertical-align:top;text-align:left"> <!--anchor--> </td> </tr> <tr> <td style="vertical-align:top;text-align:left"> <!--anchor--> <span style="font-size:13px;font-family:&quot;Century Gothic&quot;,Helvetica,sans-serif;color:#555;letter-spacing:.65px"><br> </span><!--anchor--> <span style="font-size:13px;font-family:&quot;Century Gothic&quot;,Helvetica,sans-serif;color:#555;letter-spacing:.65px">Email: <a style="color:#15c;text-decoration:underline" class="au-target" au-target-id="214" href="mailto:mareklorenz@umail.ucsb.edu">mareklorenz@umail.ucsb.edu</a></span><!--anchor--> </td> </tr><!--anchor--> <tr> <td style="vertical-align:top;text-align:left;padding-top:6px"> <img src="https://webfonts.brand.ucsb.edu/WordArt/UC_Santa_Barbara_Wordmark_Navy_RGB.300x30.png" alt="UC Santa Barbara" width="300" border="0" style="display:block;font-size:13px;font-family:&quot;Century Gothic&quot;,Helvetica,sans-serif;color:#555"> </td> </tr> <!--anchor--> </tbody> </table>';

    return (
        <div>
            <Navbar />
            <h1>Contact</h1>
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        </div>
    )
}

export default Contact
