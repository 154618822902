import React from 'react'
import './SidebarStyles.css'
import  Container  from 'react-bootstrap/Container'
import  Row  from 'react-bootstrap/Row'
import { SocialIcon } from 'react-social-icons/component'
import 'react-social-icons/github'
import 'react-social-icons/linkedin'
import 'react-social-icons/instagram'

const Sidebar = () => {
    return (
        <div className='sidebar'>
            <Container className='icons'>
                    <Row className='icon-row'><SocialIcon className="icon" network="github" url="https://www.github.com/mareklorenz"/></Row>
                    <Row className='icon-row'><SocialIcon className="icon" network="linkedin" url="https://www.linkedin.com/in/marek-lorenz/" bg-color='black'/></Row>
                    <Row className='icon-row'><SocialIcon className="icon" network="instagram" url="https://www.instagram.com/r3kzzzz/" bg-color='black'/></Row>
            </Container>
        </div>
    )
}

export default Sidebar;