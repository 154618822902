import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {FaBars, FaTimes} from 'react-icons/fa'
import './NavbarStyles.css'

const Navbar = () => {
    const[click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const [color, setColor] = useState(false)
        const changeColor =() => {
            if(window.scrollY >= 30) {
                setColor(true)
            } else {
                setColor(false)
            }
        }

        window.addEventListener('scroll', changeColor)

    return (
        <div className={color ? 'header header-bg' : 'header'}>
           <a href='/' className='logo'>MAREK LORENZ</a> 
           <ul className={click ? 'nav-menu active' : 'nav-menu'}>
               <li>
                   <Link to='/about' className='menu-icon'>about</Link>
               </li>
               <li>
                   <Link to='/work' className='menu-icon'>projetcs</Link>
               </li>
               <li>
                   <Link to='/blog' className='menu-icon'>blog</Link>
               </li>
               <li>
                   <Link to='/contact' className='contact-icon'>contact</Link>
               </li>
           </ul>
           <div className='hamburger' onClick={handleClick}>
            {click ? (<FaTimes size={20} style={{color: '#000'}} />) : (<FaBars size={20} style={{color: '#000'}} />)}    
           </div>
        </div>
    )
}

export default Navbar
